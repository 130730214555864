import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
    const settings = {
   dots: true,
    infinite: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
    };

    return (
        <section id="testimonials" className="custom-pad">
        <div className="container">
          <div className="row alc">
            <div className="col-lg-12 testimonials_col">
              <h2 className="subheading text-center">What Customer say</h2>
    
                        <Slider className='custom-dots'  {...settings} >
                            {/* {Array.from({ length: 3 }).map((_, index) => ( */}
                                <div key="1" className=" testimonials_slider">
                                    <div className="review_box bg-white rounded-lg p-6 shadow-lg">
                                        <div className="author_info flex items-center mb-4">
                                            {/* <img src="images/avtar.png" alt="avatar" className="w-12 h-12 rounded-full mr-4" /> */}
                                            <div>
                                                <h4 className="text-black font-bold">Daisy Sharma</h4>
                                                <small className="pra_bgwhite_text text-gray-500">Salon Service</small>
                                            </div>
                                        </div>
                                        <p className="pra pra_bgwhite_text text-gray-700 mb-4">
                                        I had a positive experience with the salon's door-to-door service. The convenience and quality of the service are commendable, though there are a few areas where improvements could make the experience even better.
                                        </p>
                                        <div className="review_rating flex">
                                            {[...Array(5)].map((_, starIndex) => (
                                                <span key={starIndex}><img src="images/star.svg" alt="star" className="w-4 h-4" /></span>
                                            ))}
                                        </div>
                                    </div>
                                    </div>
                                    <div key="2" className=" testimonials_slider">
                                    <div className="review_box bg-white rounded-lg p-6 shadow-lg">
                                        <div className="author_info flex items-center mb-4">
                                            {/* <img src="images/avtar.png" alt="avatar" className="w-12 h-12 rounded-full mr-4" /> */}
                                            <div>
                                                <h4 className="text-black font-bold">Sandeep Dogra</h4>
                                                <small className="pra_bgwhite_text text-gray-500">Home Cleaning Service</small>
                                            </div>
                                        </div>
                                        <p className="pra pra_bgwhite_text text-gray-700 mb-4">
                                        The booking process could be more streamlined. It would be helpful to have a more user-friendly online scheduling system.
                                        </p>
                                        <div className="review_rating flex">
                                            {[...Array(5)].map((_, starIndex) => (
                                                <span key={starIndex}><img src="images/star.svg" alt="star" className="w-4 h-4" /></span>
                                            ))}
                                        </div>
                                    </div>
                                    </div>
                                    <div key="3" className=" testimonials_slider">
                                    <div className="review_box bg-white rounded-lg p-6 shadow-lg">
                                        <div className="author_info flex items-center mb-4">
                                            {/* <img src="images/avtar.png" alt="avatar" className="w-12 h-12 rounded-full mr-4" /> */}
                                            <div>
                                                <h4 className="text-black font-bold">Vismit Magotra</h4>
                                                <small className="pra_bgwhite_text text-gray-500">Electrician Service</small>
                                            </div>
                                        </div>
                                        <p className="pra pra_bgwhite_text text-gray-700 mb-4">
                                        The door-to-door service is incredibly convenient. Having the electrician come to my location without needing to visit a shop or office saves a lot of time.
                                        </p>
                                        <div className="review_rating flex">
                                            {[...Array(5)].map((_, starIndex) => (
                                                <span key={starIndex}><img src="images/star.svg" alt="star" className="w-4 h-4" /></span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            {/* ))} */}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
 