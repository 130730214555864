import React from 'react'

const ChooseUs = () => {
    return (
        <div>
            <section id="why_choose_us" className="custom-pad bg-white">
                <div className="container">
                    <div className="row alc">
                        <div className="col-lg-6 pe-5 why_main_img">
                            <img src="images/why-choose-us.jpg" alt="why-choose-us"/>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="subheading text-left text-black">Why Choose Us</h2>
                          

                                <div class="why_icon_box_wrap">
                        <div class="why_icon_box">
                            <img src="images/verify.svg" alt="Verified Professionals"/>
                            <div class="why_icon_content">
                                <h4 class="text-black">Verified Professionals</h4>
                                <p class="pra pra_bgwhite_text">We rigorously screen and verify all our service providers to ensure you receive the highest quality of service.</p>
                            </div>
                        </div>

                        <div class="why_icon_box">
                            <img src="images/wide-range.svg" alt="Wide Range of Services"/>
                            <div class="why_icon_content">
                                <h4 class="text-black">Wide Range of Services</h4>
                                <p class="pra pra_bgwhite_text">Whether you need a plumber, a beautician, a painter, or a guest chef, we have a diverse array of services to meet your needs.</p>
                            </div>
                        </div>

                        <div class="why_icon_box">
                            <img src="images/convenience.svg" alt="Convenience"/>
                            <div class="why_icon_content">
                                <h4 class="text-black">Convenience</h4>
                                <p class="pra pra_bgwhite_text">Book services easily through our user-friendly website and schedule them at your convenience.</p>
                            </div>
                        </div>

                        <div class="why_icon_box">
                            <img src="images/customer-satisfaction.svg" alt="Customer Satisfaction"/>
                            <div class="why_icon_content">
                                <h4 class="text-black">Customer Satisfaction</h4>
                                <p class="pra pra_bgwhite_text">Our dedicated customer support team is always ready to assist you and ensure your complete satisfaction.</p>
                            </div>
                        </div>

                    </div>

                            {/* <a href="/" className="theme_btn primary_btn mt-5">Know More</a> */}

                        </div>
                    </div>
                </div>
            </section>

            <section id="profit" className="custom-pad pt-3 pb-0">
                <div className="container">
                    <div className="row alc">
                        <div className="col-lg-6">
                            <h2 className="subheading text-left">Profit Your Business,<br/> Be Our Loving Ease Partner!</h2>

                            <p class="pra">Profit Your Business, Be Our Loving Ease Partner!" is an invitation for businesses to join a partnership where they can expect to see financial benefits while enjoying a supportive and effortless collaboration. It emphasizes a win-win scenario where profitability and ease of working together are at the forefront of our offer, coupled with a warm and positive approach to partnership</p>
                            <p class="pra">Join us today and experience the ease and advantage of working with a partner who genuinely cares about your success and offers all the services you need to elevate your business.</p>


                            {/* <div className="profit_btn">
                                <a href="/" className="theme_btn primary_btn mt-4">Join now as Executive</a>
                            </div> */}
                        </div>
                        <div className="col-lg-6">
                            <img src="images/profitimg.png" alt="Profit Your Business"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ChooseUs
