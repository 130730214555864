import React from 'react'

const OurMission = () => {
    return (
        <div>
            <section id="our_mission" className="custom-pad">
                <div className="container">
                    <div className="row alc">
                        <div className="col-lg-6">
                            <h2 className="subheading text-left">Our Mission</h2>
                            <p className="pra"><b>Our mission is to simplify daily life by connecting customers with trusted, high-quality
                                home service professionals, ensuring ease and convenience in every task.</b></p>

                            <p className="pra">We strive to be the most trusted and customer-eccentric service platform, delivering excellence and reliability in every service we offer. Our goal is to save you time and effort, making your daily tasks hassle-free.</p>

                            {/* <a href="/" className="theme_btn primary_btn mt-4">Know More</a> */}
                        </div>
                        <div className="col-lg-6">
                            <img src="images/mission.jpg" alt="mission" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurMission
