import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed to 3 seconds
  };

  return (
    <section id="hero_banner">
      <div className="container-fluid">
        <div className="custom-hero-fix row alc">
          <div className="col-lg-6">
            <div className="banner_text">
              <h1>Connect with the perfect specialist just for you</h1>
              <h4>Fast and simple process for getting free estimates.</h4>
              <ul>
                <li>
                  <span className="count_list">1</span> Search for the service
                  you need
                </li>
                <li>
                  <span className="count_list">2</span> Compare and pick the
                  best Expert
                </li>
                <li>
                  <span className="count_list">3</span> Leave a review
                </li>
              </ul>

              <a href="https://wa.me/+919796950707" className="theme_btn primary_btn">
               Book Now
              </a>
            </div>
          </div>
          <div className="col-lg-6 pe-0">
            <Slider {...settings} className="bannerslide">
              <div class="items">
                <img src="images/saloon.jpg" alt="saloon" />
              </div>
              <div class="items">
                <img src="images/banner-1.png" alt="saloon" />
              </div>
              <div class="items">
                <img src="images/sallon2.jpg" alt="saloon" />
              </div>
              <div class="items">
                <img src="images/banner-2.png" alt="saloon" />
              </div>
              <div class="items">
                <img src="images/cleaning1.png" alt="saloon" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
