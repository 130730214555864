import React from 'react'
import Header from '../Common/Header'
import HeroBanner from '../Components/HeroBanner'
import ExploreCategories from '../Components/ExploreCategories'
import AboutUs from '../Components/AboutUs'
import HowItWorks from '../Components/HowItWorks'
import OurMission from '../Components/OurMission'
import ChooseUs from '../Components/ChooseUs'
import Testimonials from '../Components/Testimonials'
import Faq from '../Components/Faq'
import Footer from '../Common/Footer'
import SalonServices from '../Components/SalonServices'
import Electrician from '../Components/Electrician'
import Plumber from '../Components/Plumber'
import Carpenter from '../Components/Carpenter'
import Cleaners from '../Components/Cleaners'
import Decor from '../Components/Decor'
import GuestChef from '../Components/GuestChef'
import Painters from '../Components/Painters'
import PestControl from '../Components/PestControl'
import Tailor from '../Components/Tailor'
import Musicians from '../Components/Musicians'
import Offers from '../Components/Offers'
const HomePage = () => {
    return (
        <div>
            <Header />
            <HeroBanner />
            <ExploreCategories />
            <Offers />
            <AboutUs />
            <HowItWorks />
            <OurMission />
            <ChooseUs />
            <Testimonials />
            <Faq />
            <Footer />
            <SalonServices />
            <Electrician />
            <Plumber />
            <Carpenter />
            <Cleaners />
            <Decor />
            <GuestChef />
            <Painters />
            <PestControl />
            <Tailor />
            <Musicians />
        </div>
    )
}

export default HomePage
