import React from 'react';

const Faq = () => {
    return (
        <div>
            
    <section id="faqs" class="custom-pad">
        <div class="container">
            <div class="row alc">
                <div class="col-lg-12">
                    <h2 class="subheading text-center">Frequently Asked Questions</h2>

                    <div class="accordion accordion-flush" id="Faqs_accordion">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   How do I book a service on Loving Ease?
                </button>
              </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">
                                      
                                      <b>Booking a service on Loving Ease is simple and convenient. Follow these steps:</b>

                                      <ul>
                                        <li>i. Visit our website or Visit the Instagram Page.</li>
                                        <li>ii. Sign in or create an account if you are a new user.</li>
                                        <li>iii. Select the service you need from our wide range of options.</li>                                       
                                        <li>iv. Confirm your booking and make the payment online.</li>
                                      </ul>

                                      
                                      
                                      

                                    </p>
                                </div>
                            </div>
                        </div>
                     
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                   Can I book services for multiple locations?
                </button>
              </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">Currently, you can book services for one location per order. If you need services at multiple locations, please create separate bookings for each.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour"> How do I know if my booking is confirmed?
                </button>
              </h2>
                            <div id="flush-collapsefour" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">Once you complete the booking process, you will receive a confirmation email or whatsapp message. This confirmation will include the details of your booking.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                   Can I reschedule or cancel my booking?
                </button>
              </h2>
                            <div id="flush-collapsefive" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">Yes, you can reschedule or cancel your booking through the call. Please note that cancellation policies and fees may apply.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                   How can I contact the service provider directly?

                </button>
              </h2>
                            <div id="flush-collapse6" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">After your booking is confirmed, you will receive the contact details of your service provider. You can reach out to them directly for any specific instructions or inquiries.
</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                   What payment methods do you accept?
                </button>
              </h2>
                            <div id="flush-collapse7" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">
We accept a variety of payment methods, including credit/debit cards, net banking, and popular digital wallets. All transactions are secure and encrypted for your safety.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                   Is it safe to book services through Loving Ease?
                </button>
              </h2>
                            <div id="flush-collapse8" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">
Absolutely. We thoroughly vet and verify all our service providers to ensure they meet our high standards. Your safety and satisfaction are our top priorities.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                   What should I do if I'm not satisfied with the service?
                </button>
              </h2>
                            <div id="flush-collapse9" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">If you are not satisfied with the service provided, please contact our customer support team through the website. We will address your concerns and work to resolve any issues promptly.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                   How can I provide feedback about the service?
                </button>
              </h2>
                            <div id="flush-collapse10" class="accordion-collapse collapse" data-bs-parent="#Faqs_accordion">
                                <div class="accordion-body">
                                    <p class="pra pra_bgwhite_text">Your feedback is important to us. After the service is completed, you will receive a prompt to rate and review the service provider. You can also leave detailed feedback through your account on the Loving Ease website.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <a href="https://wa.me/+919796950707" class="theme_btn primary_btn mt-5 text-center">Contact Us Today</a>

                </div>
            </div>
        </div>
    </section>
        </div>
    );
}

export default Faq;
