import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer className="custom-pad" id="contactus">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 footer_logo">
                            <a href="/"><img src="images/logo.svg" alt="logo" /></a>

                            <ul className="contact_info">
                                <li><a href="mailto:info@lovingease.in">info@lovingease.in</a></li>
                                <li><a href="https://wa.me/+919796950707">+91-9796950707</a> </li>
                                <li>(Monday-Sunday 10:30am-08:00pm) </li>
                            </ul>

                            <div className="social-links">
                                <a href="https://www.facebook.com/share/NErhVwdG8BtgNB5h/?mibextid=qi2Omg" rel='noreferrer' target='_blank'><img src="images/facebook.svg" alt="facebook" /></a>
                                <a href="https://www.instagram.com/loving_ease?igsh=MTRjZzhpM24yanpwOQ==" rel='noreferrer' target='_blank'><img src="images/instagram.svg" alt="instagram" /></a>
                                {/* <a href="/"><img src="images/twitter.svg" alt="twitter" /></a> */}
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="footer_menu_wrap">

                                <div className="footer_wrap">
                                    <h4>For Customers</h4>
                                    <ul>
                                        <li><a href="#explore_categories">Find a Professional</a></li>
                                        <li><a href="#testimonials">Testimonial</a></li>
                                        <li><a href="#offer_slider_449">Offers</a></li>
                                     
                                    </ul>
                                </div>

                                <div className="footer_wrap">
                                    <h4>For Professional</h4>
                                    <ul>
                                        <li><a href="#our_mission">Our Mission</a></li>
                                        <li><a href="#why_choose_us">Why Choose Us </a></li>
                                        <li><a href="#faqs">FAQ's</a></li>
                                      
                                    </ul>
                                </div>

                                <div className="footer_wrap">
                                    <h4>About</h4>
                                    <ul>
                                        <li><a href="#about_us">About Loving Ease</a></li>
                                        <li><a href="#how-it-works">How it works</a></li>
                                        <li><a href="#profit">Profit</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="copyright">
                                <p className="pra">© 2024 <a href="/">lovingease.in</a>. Terms & Conditions / Privacy policy</p>
                            </div>
                        </div>
                    </div>


                </div>
            </footer>

        </div>
    )
}

export default Footer
