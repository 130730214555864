import React from "react";
import {  Row, Col } from "react-bootstrap";

const Musicians = () => {
  return (
    <div
      class="modal modal-lg fade services_modal"
      id="musicians_modal"
      tabindex="-1"
      aria-labelledby="musicians"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="musicians">
            Musicians
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {/* <Container className="fluid"> */}
              <Row className="mb-3">
                
                <Col lg={12} md={12} sm={12}>
                  <h6 className="text-right">Contact us on the given link for the prices and to book an appointment. 
                    <a href="https://wa.me/+919796950707" className="theme_btn" style={{ display: "inline",padding: "6px",marginLeft: "5px"}}> Click Here {" "}
              <img
                      style={{ height: "30px", width: "30px" }}
                      src="/images/whatsapp.png"
                      alt="WhatsApp"
                    /> 
                  </a></h6>
                </Col>
              </Row>
            {/* </Container> */}
            <div class="d-flex align-items-start">
             <p>Looking to set the perfect mood for your event? Our talented musicians are here to elevate any occasion, from weddings and parties to corporate events and private gatherings. Whether you need a solo performer, a band, or an ensemble, we offer a wide range of musical styles to match your vibe. Enjoy live music that captivates and entertains, making your event truly unforgettable. Let our skilled musicians bring the magic of music to your special day.</p>
            </div>

            <Row className="mt-3 text-center">
            <Col lg={12} md={12} sm={12}>
                  <h6 className="text-yellow">Service charges of ₹200 to be paid in advance which will be deducted from the total bill.</h6>
                </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Musicians;
