import React from "react";
import {  Row, Col } from "react-bootstrap";

const SalonServices = () => {
  return (
    <div
      class="modal fade services_modal"
      id="saloon_services_modal"
      tabindex="-1"
      aria-labelledby="saloon_services"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="saloon_services">
              Salon Services
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {/* <Container className="fluid"> */}
              <Row className="mb-3">
                
                <Col lg={12} md={12} sm={12}>
                  <h6 className="text-right">Contact us on the given link to book an appointment. 
                    <a href="https://wa.me/+919796950707" className="theme_btn" style={{ display: "inline",padding: "6px",marginLeft: "5px"}}> Click Here {" "}
              <img
                      style={{ height: "30px", width: "30px" }}
                      src="/images/whatsapp.png"
                      alt="WhatsApp"
                    /> 
                  </a></h6>
                </Col>
              </Row>
            {/* </Container> */}
            <div class="d-flex align-items-start">
              <div
                class="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  class="nav-link active"
                  id="v-pills-women-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-women"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-women"
                  aria-selected="false"
                >
                  Women
                </button>

                <button
                  class="nav-link"
                  id="v-pills-Men-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Men"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Men"
                  aria-selected="true"
                >
                  Men
                </button>
              </div>
              <div class="tab-content" id="v-pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="v-pills-women"
                  role="tabpanel"
                  aria-labelledby="v-pills-women-tab"
                  tabindex="0"
                >
                  <div class="">
                    <div class="categories_menu">
                      <div>
                        <h4>Hair Cut</h4>
                        <ul>
                          <li>
                            {" "}
                            Trimming <span>₹249/-</span>
                          </li>
                          <li>
                            {" "}
                            Fringe / Flick <span>₹299/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Baby girl (under 5years) <span>₹399/-</span>
                          </li>
                          <li>
                            {" "}
                            Haircut <span>₹599/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Styling</h4>
                        <ul>
                          <li>
                            {" "}
                            Blow Dry <span>₹249/-</span>
                          </li>
                          <li>
                            {" "}
                            Ironing <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Curls <span>₹499/-</span>
                          </li>
                          <li>
                            {" "}
                            Waves <span>₹599/-</span>
                          </li>
                          <li>
                            {" "}
                            Updo <span>₹999/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Texture Services</h4>
                        <ul>
                          <li>
                            {" "}
                            Keratin Treatment <span>₹4999/-</span>
                          </li>
                          <li>
                            {" "}
                            Botox Treatment <span>₹6999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Nanoplastia <span>₹8999/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Hair color</h4>
                        <ul>
                          <li>
                            {" "}
                            loreal / majirel root touch up <span>₹999/-</span>
                          </li>
                          <li>
                            {" "}
                            Color Chunks <span>₹1499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Highlights (Crown) <span>₹3499/-</span>
                          </li>
                          <li>
                            {" "}
                            Color Cleansing <span>₹3999/-</span>
                          </li>
                          <li>
                            {" "}
                            Deposit / Refresh <span>₹3999/-</span>
                          </li>
                          <li>
                            {" "}
                            Global <span>₹4999/-</span>
                          </li>
                          <li>
                            {" "}
                            Highlight (full head) <span>₹4999/-</span>
                          </li>
                          <li>
                            {" "}
                            Balayage <span>₹5999/-</span>
                          </li>
                          <li>
                            {" "}
                            Ombro <span>₹5999/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Hair spa</h4>
                        <ul>
                          <li>
                            {" "}
                            Premium Hair Spa <span>₹599/-</span>
                          </li>
                          <li>
                            {" "}
                            Signature Hair Spa <span>₹1199/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Head Massage</h4>
                        <ul>
                          <small>(20 minutes) (+100 for every 10minutes)</small>
                          <li>
                            {" "}
                            Dry <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Coconut <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Almond <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Olive <span>₹499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Threading</h4>
                        <ul>
                          <li>
                            {" "}
                            Eyebrows <span>₹49/-</span>
                          </li>
                          <li>
                            {" "}
                            Upper Lips <span>₹49/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Forehead <span>₹49/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Chin <span>₹49/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Side Locks <span>₹49/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Face <span>₹149/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Stripless Wax</h4>
                        <ul>
                          <li>
                            {" "}
                            Upper lips <span>₹99/-</span>
                          </li>
                          <li>
                            {" "}
                            Chin <span>₹99/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Nose <span>₹99/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Side Locks <span>₹199/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Face <span>₹499/-</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="separate_menu_box pt-5">
                    <h2>Body Wax</h2>
                    <div class="categories_menu">
                      <div>
                        <h4>Honey wax</h4>
                        <ul>
                          <li>
                            {" "}
                            Under Arms <span>₹99/-</span>
                          </li>
                          <li>
                            {" "}
                            Arms <span>₹299/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full front <span>₹349/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full back <span>₹349/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Legs <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body <span>₹1399/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Flavoured wax</h4>
                        <ul>
                          <li>
                            {" "}
                            Under Arms <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Arms <span>₹599/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full front <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full back <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Legs <span>₹799/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Bikini Wax <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full body <span>₹2999/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Stripless wax</h4>
                        <ul>
                          <li>
                            {" "}
                            Under Arms <span>₹499/-</span>
                          </li>
                          <li>
                            {" "}
                            Bikini Wax <span>₹1499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Body Rituals</h4>
                        <ul>
                          <li>
                            {" "}
                            Full Body Scrub <span>₹799/-</span>
                          </li>
                          <li>
                            {" "}
                            Classic Body Polishing <span>₹1499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Luxuary Body Polishing <span>₹2499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Hand & Foot Treatment</h4>
                        <ul>
                          <li>
                            {" "}
                            Hand Massage (20 minutes) <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Foot Massage (20 minutes) <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Premium manicure <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Premium pedicure <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Signature manicure <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Signature pedicure <span>₹999/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Nails Services</h4>
                        <ul>
                          <li>
                            {" "}
                            Nail Cutting & Filling <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Nail Paint <span>₹149/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            French nail paint <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Gel nail paint removal <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            gel nail paint <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Gel Extensions <span>₹1999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Nail art with extensions <span>₹2499/-</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="separate_menu_box pt-5">
                    <h2>Skin Services</h2>
                    <div class="categories_menu">
                      <div>
                        <h4>Facials</h4>
                        <ul>
                          <li>
                            {" "}
                            Fruit facial <span>₹999/-</span>
                          </li>
                          <li>
                            {" "}
                            Lotus <span>₹1499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Cheryl's <span>₹1999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Organic <span>₹2499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            O3+ <span>₹2999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            kanpeki <span>₹3499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Cleanups</h4>
                        <ul>
                          <li>
                            {" "}
                            D-Tan VLCC <span>₹799/-</span>
                          </li>
                          <li>
                            {" "}
                            Ozone <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Organic <span>₹1199/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            O3+ <span>₹1499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Bleach</h4>
                        <ul>
                          <li>
                            {" "}
                            Hands <span>₹99/-</span>
                          </li>
                          <li>
                            {" "}
                            Feet <span>₹99/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Under Arms <span>₹99/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Arms <span>₹199/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Legs <span>₹299/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Face bleach <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Front <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Back <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body <span>₹1499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>D-Tan</h4>
                        <ul>
                          <li>
                            {" "}
                            Hands <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Feet <span>₹149/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Arms <span>₹249/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Legs <span>₹349/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Face D-Tan <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Front <span>₹549/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Back <span>₹549/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body <span>₹1799/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Body Massages</h4>
                        <ul>
                          <li>
                            {" "}
                            Neck & Shoulder <span>₹499/-</span>
                          </li>
                          <li>
                            {" "}
                            Arm massage <span>₹599/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Back massage <span>₹599/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Leg massage <span>₹799/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body massage <span>₹2499/-</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="separate_menu_box pt-5">
                    <h2>Makeups</h2>
                    <div class="categories_menu">
                      <div>
                        <h4>Full Makeup</h4>
                        <ul>
                          <li>
                            {" "}
                            Classic makeup <span>₹3499/-</span>
                          </li>
                          <li>
                            {" "}
                            HD makeup <span>₹6999/-</span>
                          </li>
                          <li>
                            {" "}
                            Engagement makeup <span>₹15999/-</span>
                          </li>
                          <li>
                            {" "}
                            Reception makeup <span>₹29999/-</span>
                          </li>
                          <li>
                            {" "}
                            Bridal makeup <span>₹35999/-</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="v-pills-Men"
                  role="tabpanel"
                  aria-labelledby="v-pills-Men-tab"
                  tabindex="0"
                >
                  <div class="">
                    <div class="categories_menu">
                      <div>
                        <h4>Hair Cut</h4>
                        <ul>
                          <li>
                            {" "}
                            Trimming <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Baby Boy (under 5years) <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Haircut <span>₹399/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Beard Services</h4>
                        <ul>
                          <li>
                            {" "}
                            Clean shave <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Moustache <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Beard Trim <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Beard Spa <span>₹499/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Texture Services</h4>
                        <ul>
                          <li>
                            {" "}
                            Keratin Treatment <span>₹2499/-</span>
                          </li>
                          <li>
                            {" "}
                            Botox Treatment <span>₹3499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Nanoplastia <span>₹4999/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Hair color</h4>
                        <ul>
                          <li>
                            {" "}
                            Moustache <span>₹199/-</span>
                          </li>
                          <li>
                            {" "}
                            Beard <span>₹249/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Lnoa / Majirel root touchup <span>₹799/-</span>
                          </li>
                          <li>
                            {" "}
                            Highlights <span>₹2999/-</span>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Hair Spa</h4>
                        <ul>
                          <li>
                            {" "}
                            Premium Hair spa <span>₹599/-</span>
                          </li>
                          <li>
                            {" "}
                            Signature Hair Spa <span>₹799/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Head Massage</h4>
                        <ul>
                          <small>(20 minutes) (+100 for every 10minutes)</small>
                          <li>
                            {" "}
                            Dry <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Coconut <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Almond <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Olive <span>₹499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Threading</h4>
                        <ul>
                          <li>
                            {" "}
                            Eyebrows <span>₹49/-</span>
                          </li>
                          <li>
                            {" "}
                            Upper Lips <span>₹49/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Face <span>₹99/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Stripless Wax</h4>
                        <ul>
                          <li>
                            {" "}
                            Nose Wax <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Ear wax <span>₹149/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Face wax <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            underarms <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Extra wax <span>₹1499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Body Wax (Flavoured Wax)</h4>
                        <ul>
                          <li>
                            {" "}
                            Under Arms <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Arms <span>₹599/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full front <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full back <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Legs <span>₹799/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Extra wax <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body <span>₹2999/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Body Rituals</h4>
                        <ul>
                          <li>
                            {" "}
                            Full Body Scrub <span>₹799/-</span>
                          </li>
                          <li>
                            {" "}
                            Classic Body Polishing <span>₹1499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Luxuary Body Polishing <span>₹2499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Hand & Foot Treatment</h4>
                        <ul>
                          <li>
                            {" "}
                            Hand Massage (20 minutes) <span>₹299/-</span>
                          </li>
                          <li>
                            {" "}
                            Foot Massage (20 minutes) <span>₹399/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Premium manicure <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Premium pedicure <span>₹699/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Signature manicure <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Signature pedicure <span>₹999/-</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="separate_menu_box pt-5">
                    <h2>Skin Services</h2>
                    <div class="categories_menu">
                      <div>
                        <h4>Facials</h4>
                        <ul>
                          <li>
                            {" "}
                            Fruit facial <span>₹999/-</span>
                          </li>
                          <li>
                            {" "}
                            Lotus <span>₹1499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Cheryl's <span>₹1999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Organic <span>₹2499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            O3+ <span>₹2999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            kanpeki <span>₹3499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Cleanups</h4>
                        <ul>
                          <li>
                            {" "}
                            D-Tan VLCC <span>₹799/-</span>
                          </li>
                          <li>
                            {" "}
                            Ozone <span>₹999/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Organic <span>₹1199/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            O3+ <span>₹1499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Bleach</h4>
                        <ul>
                          <li>
                            {" "}
                            Hands <span>₹99/-</span>
                          </li>
                          <li>
                            {" "}
                            Feet <span>₹99/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Under Arms <span>₹99/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Arms <span>₹199/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Legs <span>₹299/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Face bleach <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Front <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Back <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body <span>₹1499/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>D-Tan</h4>
                        <ul>
                          <li>
                            {" "}
                            Hands <span>₹149/-</span>
                          </li>
                          <li>
                            {" "}
                            Feet <span>₹149/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Arms <span>₹249/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Legs <span>₹349/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Face D-Tan <span>₹499/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Front <span>₹549/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Back <span>₹549/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body <span>₹1799/-</span>{" "}
                          </li>
                        </ul>
                      </div>

                      <div>
                        <h4>Body Massages</h4>
                        <ul>
                          <li>
                            {" "}
                            Neck & Shoulder <span>₹499/-</span>
                          </li>
                          <li>
                            {" "}
                            Arm massage <span>₹599/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Back massage <span>₹599/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Leg massage <span>₹799/-</span>{" "}
                          </li>
                          <li>
                            {" "}
                            Full Body massage <span>₹2499/-</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Row className="mt-3 text-center">
            <Col lg={12} md={12} sm={12}>
                  <h6 className="text-yellow">Service charges of ₹200 to be paid in advance which will be deducted from the total bill.</h6>
                </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalonServices;
