import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ExploreCategories = () => {
  const categories = [
    { name: "Salon Services", img: "/images/sallon-cat.jpg", dataTarget: "#saloon_services_modal" },
    { name: "Electrician", img: "/images/ele.png", dataTarget: "#electrician_modal" },
    { name: "Plumber", img: "/images/plum.png", dataTarget: "#plumber_modal" },
    { name: "Carpenter", img: "/images/carpenter.png", dataTarget: "#carpenter_modal" },
    { name: "Cleaners", img: "/images/cleaning.png", dataTarget: "#cleaners_modal" },
    { name: "Decor", img: "/images/decor.jpg", dataTarget: "#decor_modal" },
    { name: "Guest Chef", img: "/images/chef.jpg", dataTarget: "#guestChef_modal" },
    { name: "Painters", img: "/images/painter.jpg", dataTarget: "#painters_modal" },
    { name: "Pest Control", img: "/images/pest-control.jpg", dataTarget: "#pestControl_modal" },
    { name: "Tailor", img: "/images/tailor.jpg", dataTarget: "#tailor_modal" },
    { name: "Musicians", img: "/images/music.jpg", dataTarget: "#musicians_modal" },

  ];


  const settings = {
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay speed to 3 seconds
    centerMode: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section id="explore_categories" className="bg-white custom-pad">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <h2 className="subheading text-center text-black ">Explore By Categories</h2>
           
            <Slider {...settings} className="categories_slider">
              {categories.map((category) => (
                <div className="items" key={category.name}>
                  <a href="/"  {...(category.dataTarget !== '' && {
                    'data-bs-toggle': 'modal', 'data-bs-target': category.dataTarget,
                  })}
                  >
                    <div className="categories_card">
                      <img src={category.img} alt={category.name} />
                      <h4>{category.name}</h4>
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExploreCategories;
