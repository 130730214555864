// import logo from './logo.svg';
import './App.css';
import HomePage from './Pages/HomePage';
// import Countdown from './Pages/Countdown';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<Countdown />} /> */}
        <Route path='/' element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
