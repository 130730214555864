import React from 'react'

const HowItWorks = () => {
    return (
        <div>
            <section id="how-it-works" className="custom-pad bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="subheading text-center text-black">How it works</h2>

                            <div className="how_work_grid">
                                <div className="icon_box_wrap w_arrow">
                                    <img src="images/book-online.svg" alt="book online" />
                                    <h4>Book Online or Phone</h4>
                                </div>

                                <div className="icon_box_wrap w_arrow second_w_arrow">
                                    <img src="images/vai-message.svg" alt="book online" />
                                    <h4>Get Booking Details Via Message</h4>
                                </div>

                                <div className="icon_box_wrap">
                                    <img src="images/pay-work.svg" alt="book online" />
                                    <h4>Pay After Work is Done</h4>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HowItWorks
