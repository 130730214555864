import React from 'react'

const AboutUs = () => {
    return (
        <section id="about_us" className="custom-pad">
            <div className="container">
                <div className="row alc">
                    <div className="col-lg-6">
                        <img src="images/about.png" alt="about" />
                    </div>
                    <div className="col-lg-6">
                        <h2 className="subheading text-left">About Us</h2>
                        <p className="pra">Welcome to Loving Ease, your one-stop solution for reliable and professional services right at your doorstep. Founded in 2024, our mission is to simplify your life by connecting you with top-notch professionals in a variety of fields, ensuring convenience, quality, and peace of mind.</p>

                        <h4>Company History and Background</h4>
                        <p className="pra">Founded in 2024, Loving Ease aims to serve thousands of households across the city. With a
                            commitment to quality and customer satisfaction, we aim become a trusted name in the home services sector.
                        </p>

                        <h4>Company Culture and Values</h4>
                        <p className="pra">We foster a culture of trust, reliability, and customer-centricity. Our values emphasize
                            quality, convenience, and respect for both our customers and service professionals.</p>

                        <h4>Goals and Objectives</h4>
                        <p className="pra">In the short term, our goal is to expand our service offerings to include more specialized and
                            seasonal home services. Long-term, we aim to establish Loving Ease as the go-to platform for home services in every major city across India, and eventually, globally.</p>

                        {/* <a href="/" className="theme_btn primary_btn mt-4">Read More</a> */}
                    </div>
                </div>
            </div>
        </section>

    );
};

export default AboutUs;