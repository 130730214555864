import React from 'react'

const Header = () => {
  return (<>
    <header id="desktop">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand main-logo" href="/">
            <img src="images/logo.svg" alt="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="#about_us">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#our_mission">Our mission</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#explore_categories">Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contactus">Contact us</a>
              </li>
            </ul>
            <a href="https://wa.me/+919796950707" className="theme_btn ms-4">Join as a Professional</a>
          </div>
        </div>
      </nav>
    </header>
    <header id="mobile">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand main-logo" href="/">
            <img src="images/logo.svg" alt="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
            <img src="images/toggle.svg" alt="toggle" />
          </button>
          <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <img src="images/logo.svg" alt="logo" />
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item" data-bs-toggle="offcanvas" data-bs-target=".offcanvas.show">
                  <a class="nav-link" href="/#about_us"  >About</a>
                </li>
                <li class="nav-item"  data-bs-toggle="offcanvas" data-bs-target=".offcanvas.show">
                  <a class="nav-link" href="/#our_mission">Our mission</a>
                </li>
                <li class="nav-item"  data-bs-toggle="offcanvas" data-bs-target=".offcanvas.show">
                  <a class="nav-link" href="/#explore_categories">Services</a>
                </li>
                <li class="nav-item"  data-bs-toggle="offcanvas" data-bs-target=".offcanvas.show">
                  <a class="nav-link" href="/#contactus">Contact us</a>
                </li>
              </ul>
              <a href="https://wa.me/+919796950707" className="theme_btn mt-3">Join as a Professional</a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </>);
};

export default Header
