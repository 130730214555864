import React from "react";
import {  Row, Col } from "react-bootstrap";

const Carpenter = () => {
  return (
    <div
      class="modal modal-lg fade services_modal"
      id="carpenter_modal"
      tabindex="-1"
      aria-labelledby="carpenter"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="carpenter">
            Carpenter
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            {/* <Container className="fluid"> */}
              <Row className="mb-3">
                
                <Col lg={12} md={12} sm={12}>
                  <h6 className="text-right">Contact us on the given link for the prices and to book an appointment. 
                    <a href="https://wa.me/+919796950707" className="theme_btn" style={{ display: "inline",padding: "6px",marginLeft: "5px"}}> Click Here {" "}
              <img
                      style={{ height: "30px", width: "30px" }}
                      src="/images/whatsapp.png"
                      alt="WhatsApp"
                    /> 
                  </a></h6>
                </Col>
              </Row>
            {/* </Container> */}
            <div class="d-flex align-items-start">
             <p>Need expert carpentry services? Our skilled carpenters specialize in everything from custom furniture creation to minor repairs and installations. Whether you're looking to build something new, refurbish old woodwork, or need precise fittings, our professionals ensure top-notch craftsmanship and attention to detail. Get the perfect finish for your home or office with our reliable and efficient carpentry solutions.</p>
            </div>

            <Row className="mt-3 text-center">
            <Col lg={12} md={12} sm={12}>
                  <h6 className="text-yellow">Service charges of ₹200 to be paid in advance which will be deducted from the total bill.</h6>
                </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carpenter;
