import React from 'react';
const Offers = () => {
        
    return (
        <div>            
            <section id="offer_slider_449" className="custom-pad">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 p-0">
                    <h2 className="subheading text-white text-center">Loving Ease Offers</h2>

                    
              
                    <div className="loving_offer_slider">                   

                        <div className="items" key="1">
                            <a href="/">
                                <span>30% Off</span>
                                <div className="offers_card">
                                    <h4>INAUGURAL OFFER</h4>
                                    <p>Flat 30% off on all salon services above ₹999/-</p>
                                </div>
                            </a>
                        </div>                 
                        </div>                 

                   


                </div>
            </div>
        </div>
    </section>
        </div>
    );
}

export default Offers;
